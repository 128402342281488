<template>
    <div>
        <ValidationObserver ref="statusForm">
            <div class="row">
                <div class="col-12">
                    <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                        <b-form-group>
                            <graduate-application-student-status-selectbox
                                v-model="status"
                                :validate-error="errors[0]"></graduate-application-student-status-selectbox>
                        </b-form-group>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <b-button variant="primary" @click="saveStatus">{{$t('save')}}</b-button>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import GraduateApplicationStudentStatusSelectbox from "@/components/interactive-fields/GraduateApplicationStudentStatusSelectbox";

// Services
import GraduateApplicationService from "@/services/GraduateApplicationService";

// Other
import {ValidationObserver, ValidationProvider} from "vee-validate"

export default {
    components: {
        GraduateApplicationStudentStatusSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    props: {
        row: {
            type: Object
        },
        graduateApplicationId: {
            type: Number
        },
        nationalId: {
            type: String
        },
        pin: {
            type: String
        }
    },
    data() {
        return {
            status: null
        }
    },
    watch: {
        row: function () {
            this.getStudentStatus()
        }
    },
    created() {

    },
    methods: {
        getStudentStatus() {
            let config = {
                national_id: this.nationalId,
                pin: this.pin
            }
            GraduateApplicationService.show(config).then(response => {
                let data = response.data.data
                data.preferences.forEach(itm => {
                    if (itm.id == this.row.perferenceId) {
                        if (itm.student_status) {
                            this.status = itm.student_status
                        }
                    }
                })
            }).catch(e => {
                if (e.status == '404' || e.status == '406') {
                    if (e.data.message) {
                        this.$toast.error(this.$t('api.' + e.data.message));
                    }
                }
            })
        },

        async saveStatus() {
            const isValid = await this.$refs.statusForm.validate();
            if (isValid) {
                let formData = {
                    graduate_application_id: this.graduateApplicationId,
                    preference_id: this.row.perferenceId,
                    student_status: this.status
                }
                GraduateApplicationService.studentStatusSave(formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$emit('close', true)
                }).catch(e => {
                    if (e.status == '404' || e.status == '406') {
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                })
            }
        }
    }
}
</script>
