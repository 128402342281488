<template>
	<div>
        <ValidationObserver ref="statusForm">
        <div class="row">
            <div class="col-12">
                <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                    <b-form-group>
                        <parameter-selectbox
                            v-model="status"
                            code="graduate_application_preference_status"
                            :validate-error="errors[0]"></parameter-selectbox>
                    </b-form-group>
                </ValidationProvider>
            </div>
            <div class="col-12">
                <b-button variant="primary" @click="saveStatus">{{$t('save')}}</b-button>
            </div>
        </div>
        </ValidationObserver>
    </div>
</template>

<script>
	// Components
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";

	// Services
    import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";

	export default {
		components: {
            ParameterSelectbox,

			ValidationProvider,
			ValidationObserver,
		},
		props: {
            row: {
                type: Object
            },
            graduateApplicationId: {
                type: Number
            },
            pin: {
                type: String
            }
		},
		data() {
			return {
                status: null
			}
		},
		watch: {
            row: function(val){
                this.status = val.status
            }
		},
		created() {

		},
		methods: {
            async saveStatus(){
                const isValid = await this.$refs.statusForm.validate();
                if (isValid) {
                    let formData = {
                        graduate_application_id: this.graduateApplicationId,
                        pin: this.pin,
                        status: this.status
                    }
                    GraduateApplicationService.preferenceStatusChange(this.row.perferenceId, formData).then(response => {
                        this.$toast.success(this.$t('api.'+response.data.message))
                        this.$emit('close',true)
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.'+e.data.message));
                            }
                        }
                    })
                }
            }
        }
	}
</script>
