// Event Bus
import {EventBus} from '@/main'

// SweetAlert
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
// Plugins
import i18n from '@/plugins/i18n'

// Services
import GraduateApplicationService from "@/services/GraduateApplicationService";

// Helpers
import showErrors from '@/helpers/showErrors'

const options = {
    confirmButtonColor: '#41b882',
    cancelButtonColor: '#ffffff',
    icon: 'question'
}
Vue.use(VueSweetalert2, options);

export default function(obj,documentType) {
    let document;

    switch(documentType) {

        case 'document':
            document = {
                callback: () => {
                    GraduateApplicationService.downloadDocument(obj.graduateApplicationId, obj.document).then(response => {
                        EventBus.$emit('pdfViewerModalShow', {
                            pdfFileData: response.data,
                            pdfFileName: 'xxx.pdf',
                        })
                    }).catch(e => {
                        showErrors(e, null, true)
                    })
                }
            }

            break;

        default:
            document = false
    }


    return document;
}


