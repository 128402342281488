<template>
	<div>
        intibak
    </div>
</template>

<script>
	// Components

	// Services
    import GraduateApplicationService from "@/services/GraduateApplicationService";

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from "qs";

	export default {
		components: {
			ValidationProvider,
			ValidationObserver,
		},
		props: {
            row: {
                type: Object
            },
            graduateApplicationId: {
                type: Number
            },
		},
		data() {
			return {

			}
		},
		watch: {
            row: function(){
                this.getCourseTransfers()
            },

		},
		created() {

		},
		methods: {
             getCourseTransfers(){
                 let config = {
                     graduate_application_id: this.graduateApplicationId,
                     graduate_application_preference_id: this.row.perferenceId
                 }
                GraduateApplicationService.getCourseTransfers(config).then(response => {

                }).catch(e => {

                })
             }
        }
	}
</script>
